<template>
  <div>
    <multiselect
      v-model="valueSelect"
      :options="options"
      :multiple="false"
      :label="label"
      :track-by="trackBy"
      :placeholder="placeholder"
      :required="required"
      :showLabels="false"
      class="select-vue-component-style select-simple-with-search mt-1"
      @input="handleChangeSelect"
      :title="title"
      :disabled="disabled"
      :loading="loading"
      :close-on-select="closeOnSelect"
      :clear-on-select="clearOnSelect"
      :preserve-search="preserveSearch"
      :preselect-first="preselectFirst"
      :allow-empty="allowEmpty"
    >
      <span slot="noResult">{{ noResult }}</span>
      <span slot="noOptions">{{ noResult }}</span>
    </multiselect>
  </div>
</template>
<script>
export default {
  props: {
    options: { required: true },
    label: { default: 'name' },
    trackBy: { default: 'id' },
    placeholder: { default: 'sélectionner une option' },
    required: { default: true },
    value: { require: true },
    disabled: { default: false },
    loading: { default: false },
    closeOnSelect: { default: true },
    clearOnSelect: { default: true },
    preserveSearch: { default: false },
    preselectFirst: { default: false },
    noResult: { default: 'Aucune société trouvé.' },
    refreshValue: { default: false },
    title: {
      default: ''
    },
    allowEmpty: { default: true }
  },
  data() {
    return {
      valueSelect: this.computedValue
    };
  },
  methods: {
    handleChangeSelect() {
      this.$emit(
        'changeValue',
        this.valueSelect != null
          ? this.valueSelect[this.trackBy]
          : this.valueSelect
      );
    }
  },
  computed: {
    computedValue() {
      let op = this.options.filter(i => i[this.trackBy] == this.value);
      return this.value != null ? (op.length ? op[0] : null) : this.value;
    }
  },
  watch: {
    value() {
      if (this.refreshValue) {
        this.valueSelect = this.computedValue;
      }
    }
  },
  mounted() {
    this.valueSelect = this.computedValue;
  }
};
</script>

<style>
.select-simple-with-search .multiselect__spinner {
  position: absolute;
  right: 3px;
  top: 1px;
  width: 33px;
  height: 26px;
  background: #fff;
  display: block;
  border-radius: 50px;
}
</style>
