<template>
  <div class="gestion-organisme-new-th">
    <div class="box-setting-header">
      <div class="title ml-4 mt-2">Gestion des organismes</div>
      <div>
        <b-button
          v-if="checkPermission('GOTHAO')"
          variant="success"
          class="config-btn ml-2"
          v-b-modal.addOrganismeNew
        >
          <font-awesome-icon icon="plus" /> Ajouter un organisme
        </b-button>
        <b-button
          class="btn-success btn-sm ml-2 radues-locked-icon"
          @click="handelBlokedRows"
          v-if="checkPermission('GOTHVO') && computedCheckedRows.length"
        >
          <font-awesome-icon icon="lock" />
        </b-button>
        <b-modal
          id="addOrganismeNew"
          ref="addOrganismeNew"
          :hide-footer="true"
          :hide-header="true"
          no-close-on-backdrop
          @hidden="resetModal"
          modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-frais"
        >
          <div class="hader mb-2">
            <div class="titleSetting">Ajouter un organisme</div>
            <div
              class="iconClose"
              @click.prevent="hideModal('addOrganismeNew')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="hendleSubmitOrganismeNew"
                class="form-modal-custom-style"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="row ml-1">
                      <b-form-group
                        label="Organisme"
                        label-for="organisme"
                        class="input-modal-champ mb-0 col-3"
                      >
                        <b-form-select
                          autocomplete="off"
                          id="organisme"
                          v-model="organismeNewToAdd.organisme"
                          :options="computedListOrganisme"
                          class="b-form-select-raduis"
                          text-field="name"
                          value-field="id"
                          @change="checkErrorsAdd"
                        ></b-form-select>
                      </b-form-group>

                      <b-form-group
                        label="Group"
                        label-for="group"
                        class="input-modal-champ mb-0 col-3"
                      >
                        <select-simple-with-search
                          :value="organismeNewToAdd.group"
                          :options="computedListGroup"
                          placeholder="sélectionner un groupe"
                          @changeValue="
                            organismeNewToAdd.group = $event;
                            checkErrorsAdd();
                          "
                          noResult="Aucun Groupe trouvé."
                        />
                      </b-form-group>

                      <b-form-group
                        label="Date de début"
                        label-for="dateDebut"
                        class="input-modal-champ p-1 col-3"
                      >
                        <date-picker
                          value-type="format"
                          type="date"
                          v-model="organismeNewToAdd.date_debut"
                          :clear-button="true"
                          @input="checkErrorsAdd"
                          :disabled-date="disabledStartDate"
                        ></date-picker>
                      </b-form-group>
                      <b-form-group
                        label="Date de fin"
                        label-for="dateFin"
                        class="input-modal-champ p-1 col-3"
                      >
                        <date-picker
                          value-type="format"
                          type="date"
                          v-model="organismeNewToAdd.date_fin"
                          :clear-button="true"
                          @input="checkErrorsAdd"
                          :disabled-date="disabledEndDate"
                        ></date-picker>
                      </b-form-group>
                    </div>
                    <div class=" ml-1">
                      <div class="messageError">
                        <div v-if="errorDupplicate" class="error">
                          <ul v-if="Array.isArray(errorDupplicate)">
                            <li
                              v-for="(e, index) in errorDupplicate"
                              :key="index"
                            >
                              {{ e }}
                            </li>
                          </ul>
                          <div v-else>{{ errorDupplicate }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="row ml-1">
                      <b-form-group
                        label="Label Coef prime"
                        label-for="label_commercial_prime"
                        class="input-modal-champ p-1 col-3"
                      >
                        <b-form-input
                          id="label_commercial_prime"
                          v-model="organismeNewToAdd.label_commercial_prime"
                          autocomplete="off"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Coef prime Formule :"
                        label-for="coef_commercial_prime"
                        class="input-modal-champ p-1 col-3"
                      >
                        <!-- <b-form-input
                          id="coef_commercial_prime"
                          v-model="organismeNewToAdd.coef_commercial_prime"
                          autocomplete="off"
                          disabled
                          required
                        ></b-form-input> -->
                        <b-form-input
                          autocomplete="off"
                          id="filiale-name"
                          v-model="organismeNewToAdd.coef_commercial_prime"
                          rows="3"
                          required
                          @keyup="checkFormuleConfigFraisDynamic('add')"
                          class="b-form-select-raduis"
                        ></b-form-input>
                        <em class="text-danger">{{ errorFormule }} </em>
                      </b-form-group>
                      <b-form-group
                        :label="
                          isTextCopied
                            ? 'Colonne :   Text copier ...'
                            : 'Colonne : '
                        "
                        label-for="colonne"
                        class="input-modal-champ p-1 col-3"
                      >
                        <b-form-select
                          autocomplete="off"
                          :id="'condition_type'"
                          @input="CopyFields($event)"
                          :options="getNumeriqCulomn"
                          class="b-form-select-raduis"
                        ></b-form-select>
                        <!-- <div>
                          <div class="CopieMsg" v-if="true">
                            Text copier
                          </div>
                        </div> -->
                      </b-form-group>

                      <b-form-group
                        label="Formule finale"
                        label-for="formule"
                        class="input-modal-champ p-1 col-3"
                      >
                        <em class="d-flex justify-content-start text-dark">
                          {{
                            computedFormule(
                              organismeNewToAdd.coef_commercial_prime
                            )
                          }}
                        </em>
                      </b-form-group>
                    </div>
                    <div class="row ml-1">
                      <b-form-group
                        label="Tva Coef prime"
                        label-for="tva_coef_commercial_prime"
                        class="input-modal-champ p-1 col-6"
                      >
                        <b-form-input
                          id="tva_coef_commercial_prime"
                          type="number"
                          step="any"
                          v-model="organismeNewToAdd.tva_coef_commercial_prime"
                          autocomplete="off"
                          required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        label="Description Coef prime"
                        label-for="description__commercial_prime"
                        class="input-modal-champ p-1 col-6"
                      >
                        <b-form-textarea
                          :class="'textarea-societe'"
                          id="description__commercial_prime"
                          rows="1"
                          v-model="
                            organismeNewToAdd.description__commercial_prime
                          "
                        ></b-form-textarea>
                      </b-form-group>
                    </div>
                    <div class="row ml-1">
                      <b-form-group
                        label="Label complémentaire"
                        label-for="label_missing_prime"
                        class="input-modal-champ p-1 col-6"
                      >
                        <b-form-input
                          id="label_missing_prime"
                          v-model="organismeNewToAdd.label_missing_prime"
                          autocomplete="off"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Description complémentaire"
                        label-for="description_missing_prime"
                        class="input-modal-champ p-1 col-6"
                      >
                        <b-form-textarea
                          class="textarea-societe"
                          id="description_missing_prime"
                          rows="2"
                          v-model="organismeNewToAdd.description_missing_prime"
                        ></b-form-textarea>
                      </b-form-group>
                    </div>

                    <div class="row ml-1">
                      <b-form-group
                        label="Label complémentaire avoir"
                        label-for="label_missing_prime_av"
                        class="input-modal-champ p-1 col-6"
                      >
                        <b-form-input
                          id="label_missing_prime_av"
                          v-model="organismeNewToAdd.label_missing_prime_av"
                          autocomplete="off"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Description complémentaire avoir"
                        label-for="description_missing_prime_av"
                        class="input-modal-champ p-1 col-6"
                      >
                        <b-form-textarea
                          class="textarea-societe"
                          id="description_missing_prime_av"
                          rows="2"
                          v-model="
                            organismeNewToAdd.description_missing_prime_av
                          "
                        ></b-form-textarea>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="row ml-1">
                      <b-form-group
                        label="Coef Prime Installateur"
                        label-for="coef_prime_installateur"
                        class="input-modal-champ p-1 col-6"
                      >
                        <b-form-input
                          id="coef_prime_installateur"
                          type="number"
                          step="any"
                          v-model="organismeNewToAdd.coef_prime_installateur"
                          autocomplete="off"
                          required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        label="Tva Coef Prime Installateur"
                        label-for="tva_coef_prime_installateur"
                        class="input-modal-champ p-1 col-6"
                      >
                        <b-form-input
                          id="tva_coef_prime_installateur"
                          v-model="
                            organismeNewToAdd.tva_coef_prime_installateur
                          "
                          autocomplete="off"
                          disabled
                          required
                        ></b-form-input>
                      </b-form-group>
                    </div>

                    <div class="row ml-1">
                      <b-form-group
                        label="Label Coef commission commercial group"
                        label-for="label_commercial_group"
                        class="input-modal-champ p-1 col-3"
                      >
                        <b-form-input
                          id="label_commercial_group"
                          v-model="organismeNewToAdd.label_commercial_group"
                          autocomplete="off"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Coef commission commercial group"
                        label-for="coef_commercial_group"
                        class="input-modal-champ p-1 col-3"
                      >
                        <b-form-input
                          id="coef_commercial_group"
                          v-model="organismeNewToAdd.coef_commercial_group"
                          autocomplete="off"
                          type="number"
                          step="any"
                          required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        label="Tva coef commission commercial groupe"
                        label-for="tva_coef_commercial_group"
                        class="input-modal-champ p-1 col-3"
                      >
                        <b-form-input
                          id="tva_coef_commercial_group"
                          v-model="organismeNewToAdd.tva_coef_commercial_group"
                          autocomplete="off"
                          type="number"
                          step="any"
                          required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        label="Description coef commission commercial groupe"
                        label-for="description_commercial_group"
                        class="input-modal-champ p-1 col-3"
                      >
                        <b-form-textarea
                          class="textarea-societe"
                          id="description_commercial_group"
                          rows="3"
                          v-model="
                            organismeNewToAdd.description_commercial_group
                          "
                        ></b-form-textarea>
                      </b-form-group>
                    </div>
                    <div class="row ml-1">
                      <b-form-group
                        label="Label Coef surprime group"
                        label-for="label_surprime_group"
                        class="input-modal-champ p-1 col-3"
                      >
                        <b-form-input
                          id="label_surprime_group"
                          v-model="organismeNewToAdd.label_surprime_group"
                          autocomplete="off"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Coef surprime group"
                        label-for="coef_surprime_group"
                        class="input-modal-champ p-1 col-3"
                      >
                        <b-form-input
                          id="coef_surprime_group"
                          v-model="organismeNewToAdd.coef_surprime_group"
                          autocomplete="off"
                          required
                          type="number"
                          step="any"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        label="Tva coef surprime groupe"
                        label-for="tva_coef_surprime_group"
                        class="input-modal-champ p-1 col-3"
                      >
                        <b-form-input
                          id="tva_coef_surprime_group"
                          v-model="organismeNewToAdd.tva_coef_surprime_group"
                          autocomplete="off"
                          required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        label="Description coef surprime groupe"
                        label-for="description_surprime_group"
                        class="input-modal-champ p-1 col-3"
                      >
                        <b-form-textarea
                          class="textarea-societe"
                          id="description_surprime_group"
                          rows="3"
                          v-model="organismeNewToAdd.description_surprime_group"
                        ></b-form-textarea>
                      </b-form-group>
                    </div>
                    <div class="row ml-1">
                      <b-form-group
                        label="Label Commissions Gestion dossier et administrative"
                        label-for="label_commission_gestion_dossier_administrative"
                        class="input-modal-champ p-1 col-3"
                      >
                        <b-form-input
                          id="label_commission_gestion_dossier_administrative"
                          v-model="
                            organismeNewToAdd.label_commission_gestion_dossier_administrative
                          "
                          autocomplete="off"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Coef Commissions Gestion dossier et administrative"
                        label-for="coef_commission_gestion_dossier_administrative"
                        class="input-modal-champ p-1 col-3"
                      >
                        <b-form-input
                          id="coef_commission_gestion_dossier_administrative"
                          v-model="
                            organismeNewToAdd.coef_commission_gestion_dossier_administrative
                          "
                          autocomplete="off"
                          required
                          type="number"
                          step="any"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        label="Tva Commissions Gestion dossier et administrative"
                        label-for="tva_coef_commission_gestion_dossier_administrative"
                        class="input-modal-champ p-1 col-3"
                      >
                        <b-form-input
                          id="tva_coef_commission_gestion_dossier_administrative"
                          v-model="
                            organismeNewToAdd.tva_coef_commission_gestion_dossier_administrative
                          "
                          autocomplete="off"
                          required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        label="Description Commissions Gestion dossier et administrative"
                        label-for="description_commission_gestion_dossier_administrative"
                        class="input-modal-champ p-1 col-3"
                      >
                        <b-form-textarea
                          class="textarea-societe"
                          id="description_commission_gestion_dossier_administrative"
                          rows="3"
                          v-model="
                            organismeNewToAdd.description_commission_gestion_dossier_administrative
                          "
                        ></b-form-textarea>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="actionModel mt-3">
                  <div class="messageError">
                    <div v-if="error" class="error">
                      <ul v-if="Array.isArray(error)">
                        <li
                          v-for="(e, index) in error"
                          :key="index"
                          style="list-style-type: none"
                        >
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ error }}</div>
                    </div>
                  </div>
                  <b-button
                    type="submit"
                    class="button-valide-style"
                    :disabled="!validateToAddconf"
                  >
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
      </div>
    </div>
    <div class="ligne-box-setting-header"></div>
    <div class="box-all-filter">
      <div class="box-label-champ mr-2">
        <div class="label-box-style">
          <span class="title-tabel">Organisme</span>
        </div>
        <b-form-select
          class="b-form-select-new-style"
          v-model="filterOrganisme"
          :options="[{ id: null, name: 'Tous' }, ...computedListOrganisme]"
          text-field="name"
          value-field="id"
          @change="handleChangeOrganisme"
        ></b-form-select>
      </div>

      <div class="box-label-champ mr-2">
        <div class="label-box-style">
          <span class="title-tabel">Groupe</span>
        </div>
        <b-form-select
          class="b-form-select-new-style"
          v-model="filterGroup"
          :options="[{ id: null, name: 'Tous' }, ...computedListGroup]"
          text-field="name"
          value-field="id"
          @change="handleChangeGroup"
        ></b-form-select>
      </div>

      <div class="box-label-champ mr-2">
        <div class="label-box-style">
          <span class="title-tabel">Date début</span>
        </div>
        <date-picker
          value-type="format"
          type="date"
          v-model="dateStartForSearch"
          :clear-button="true"
          @input="handleChangeDate_debut"
          :disabled-date="disabledStartDateFilter"
        ></date-picker>
      </div>

      <div class="box-label-champ mr-2">
        <div class="label-box-style">
          <span class="title-tabel">Date fin</span>
        </div>
        <date-picker
          value-type="format"
          type="date"
          v-model="dateEndForSearch"
          :clear-button="true"
          @input="handleChangeDate_fin"
          :disabled-date="disabledEndDateFiltre"
        ></date-picker>
      </div>
      <div v-if="getOrganismeThNewLoading" class="loading">
        <div class="spinner-border loadingFetch" role="status"></div>
      </div>
      <!-- <div v-if="getOrganismeThNewLoading" class="chargement-message-text ml-2">
        Chargement en cours
      </div> -->
      <div class="error-message">
        <div v-if="getOrganismeThNewError" class="error">
          <ul v-if="Array.isArray(getOrganismeThNewError)">
            <li v-for="(e, index) in getOrganismeThNewError" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ getOrganismeThNewError }}</div>
        </div>
      </div>
    </div>
    <div>
      <div
        v-if="
          filterOrganisme !== null ||
            filterGroup !== null ||
            dateStartForSearch !== null ||
            dateEndForSearch !== null
        "
        class="block-filter-style"
      >
        <span class="phrase-style">Les filtres choisie sont : </span>
        <span class="item-filter-style" v-if="dateStartForSearch !== null"
          >Date début : {{ formateDateGetters(dateStartForSearch) }}</span
        >
        <span class="item-filter-style" v-if="dateEndForSearch !== null"
          >Date fin : {{ formateDateGetters(dateEndForSearch) }}</span
        >
        <span v-if="filterGroup !== null" class="item-filter-style"
          >Groupe : {{ computedFilterGroup }}</span
        >
        <span v-if="filterOrganisme !== null" class="item-filter-style"
          >Organisme : {{ computedFilterOrganisme }}</span
        >
      </div>
    </div>
    <div class="organisme-new-body" :style="sizeBlockTable">
      <div class="table-rapport-style organisme-details w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style table-organisme-new"
          :items="getOrganismeThNew"
          :fields="fields"
          bordered
          sticky-header
          hover
          :style="sizeTable"
          head-variant="light"
          empty-text="Il n'y a aucun enregistrement à afficher"
          tbody-tr-class="ligneNormale"
        >
          <template v-slot:cell(Actions)="data">
            <b-button
              v-if="checkPermission('GOTHMO')"
              class="button-succes-style ml-2"
              size="sm"
              variant="succes"
              @click.prevent.stop="handleUpdate(data.item)"
              title="Modifier"
            >
              <font-awesome-icon icon="pencil-alt" />
            </b-button>
            <b-button
              v-if="checkPermission('GOTHSO')"
              class="button-danger-style ml-2"
              size="sm"
              variant="danger"
              :disabled="data.item.locked === 1"
              @click.prevent.stop="deleteOrganismeNew(data.item)"
              title="Supprimer"
            >
              <font-awesome-icon icon="trash" />
            </b-button>
            <b-button
              v-if="checkPermission('GOTHDO')"
              class="btn-secondary btn-sm ml-2"
              :disabled="data.item.locked === 1"
              @click.prevent.stop="DupplicateOrganismeNew(data.item)"
              title="Dupliquer"
            >
              <font-awesome-icon icon="clone" />
            </b-button>
          </template>
          <template class="check-th" v-slot:head()="data">
            <div v-if="data.field.key === 'check_all'">
              <b-form-checkbox
                v-model="checkAll"
                @change="checkAllFunction"
                class="check-th"
                :value="true"
                :unchecked-value="false"
              >
              </b-form-checkbox>
            </div>
          </template>
          <template class="width-service" v-slot:cell(check_all)="data"
            >{{ data.item.check_all }}
            <b-form-checkbox
              v-model="data.item.check"
              @change="checkOneFunction(data.item.check)"
              class="check-th"
              :value="true"
              :unchecked-value="false"
            >
            </b-form-checkbox>
          </template>
          <template class="width-service" v-slot:cell(lock_icon)="data">
            <font-awesome-icon v-if="data.item.locked === 1" icon="lock" />
            <font-awesome-icon v-if="data.item.locked === 0" icon="lock-open" />
          </template>
          <template v-slot:cell(organisme)="data">
            {{ data.item.organisme.name }}
          </template>
          <template v-slot:cell(group)="data">
            {{ data.item.group.name }}
          </template>
          <template v-slot:cell(date_debut)="data">
            {{ data.item.date_debut }}
          </template>
          <template v-slot:cell(date_fin)="data">
            {{ data.item.date_fin ? data.item.date_fin : '-' }}
          </template>

          <template v-slot:cell(coef_prime_installateur)="data">
            {{ data.item.coef_prime_installateur }}
          </template>

          <template v-slot:cell(coef_commercial_prime)="data">
            {{ data.item.coef_commercial_prime }}
          </template>

          <template v-slot:cell(coef_commercial_group)="data">
            {{ data.item.coef_commercial_group }}
          </template>

          <template v-slot:cell(coef_surprime_group)="data">
            {{ data.item.coef_surprime_group }}
          </template>
          <template
            v-slot:cell(coef_commission_gestion_dossier_administrative)="data"
          >
            {{ data.item.coef_commission_gestion_dossier_administrative }}
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowOrganismeThNew"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="deleteOrganismeThNewModal"
      id="deleteOrganismeThNewModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Supprimer un organisme</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('deleteOrganismeThNewModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>

      <form
        @submit.prevent="handleSuprimerOrganismeNew"
        class="form-modal-custom-style"
      >
        <div class="mt-4 ml-3">
          <p>
            Êtes-vous sur de vouloir supprimer cet organisme
            <strong>
              {{ selectedOrganisme ? selectedOrganisme.organisme.name : '-' }}
            </strong>
            ?
          </p>
        </div>
        <div class="actionModel">
          <div class="messageError">
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="'error' + index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <b-button
            type="submit"
            class="button-valide-style mt-1"
            :disabled="getOrganismeThNewLoading"
          >
            <span>
              Valider
              <div v-if="getOrganismeThNewLoading" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modal-update-organisme-params"
      ref="modal-update-organisme-params"
      title="Configuration"
      no-close-on-backdrop
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-frais"
    >
      <div class="hader mb-3">
        <div class="titleSetting">Modifier un organisme</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('modal-update-organisme-params')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleSubmitUpdateOrganismeNew"
            v-if="organismeNewToUpdate"
            class="form-modal-custom-style"
          >
            <div>
              <b-button
                variant="success"
                class="bien-add-button ml-2"
                @click.prevent.stop="handleModalParametreSpec"
                v-if="checkPermission('GOTHAPSO')"
              >
                <font-awesome-icon icon="plus" /> Paramètres Spécifique
              </b-button>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row ml-1">
                  <b-form-group
                    label="Organisme"
                    label-for="organisme"
                    class="input-modal-champ mb-0 col-3"
                  >
                    <b-form-select
                      autocomplete="off"
                      id="organisme"
                      v-model="organismeNewToUpdate.organisme.id"
                      :options="computedListOrganisme"
                      class="b-form-select-raduis"
                      text-field="name"
                      value-field="id"
                      @change="checkErrorsUpdate"
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group
                    label="Group"
                    label-for="group"
                    class="input-modal-champ mb-0 col-3"
                  >
                    <select-simple-with-search
                      :value="organismeNewToUpdate.group.id"
                      :options="computedListGroup"
                      placeholder="sélectionner un groupe"
                      @changeValue="
                        organismeNewToUpdate.group.id = $event;
                        checkErrorsUpdate();
                      "
                      noResult="Aucun Groupe trouvé."
                    />
                  </b-form-group>

                  <b-form-group
                    label="Date de début"
                    label-for="dateDebut"
                    class="input-modal-champ p-1 col-3"
                  >
                    <date-picker
                      value-type="format"
                      type="date"
                      v-model="organismeNewToUpdate.date_debut"
                      :clear-button="true"
                      :disabled-date="disabledStartDateUpdate"
                      required
                      @change="checkErrorsUpdate"
                    ></date-picker>
                  </b-form-group>

                  <b-form-group
                    label="Date de fin"
                    label-for="dateFin"
                    class="input-modal-champ p-1 col-3"
                  >
                    <date-picker
                      value-type="format"
                      type="date"
                      v-model="organismeNewToUpdate.date_fin"
                      :clear-button="true"
                      :disabled-date="disabledEndDateUpdate"
                      @change="checkErrorsUpdate"
                    ></date-picker>
                  </b-form-group>
                </div>
                <div class=" ml-1">
                  <div class="messageError">
                    <div v-if="errorDupplicate" class="error">
                      <ul v-if="Array.isArray(errorDupplicate)">
                        <li v-for="(e, index) in errorDupplicate" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ errorDupplicate }}</div>
                    </div>
                  </div>
                </div>
                <div class="row ml-1">
                  <b-form-group
                    label="Label Coef prime"
                    label-for="label_commercial_prime"
                    class="input-modal-champ p-1 col-3"
                  >
                    <b-form-input
                      id="label_commercial_prime"
                      v-model="organismeNewToUpdate.label_commercial_prime"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Coef prime Formule :"
                    label-for="coef_commercial_prime"
                    class="input-modal-champ p-1 col-3"
                  >
                    <!-- <b-form-input
                          id="coef_commercial_prime"
                          v-model="organismeNewToUpdate.coef_commercial_prime"
                          autocomplete="off"
                          disabled
                          required
                        ></b-form-input> -->
                    <b-form-input
                      autocomplete="off"
                      id="filiale-name"
                      v-model="organismeNewToUpdate.coef_commercial_prime"
                      rows="3"
                      required
                      @keyup="checkFormuleConfigFraisDynamic('update')"
                      class="b-form-select-raduis"
                    ></b-form-input>
                    <em class="text-danger">{{ errorFormule }} </em>
                  </b-form-group>
                  <b-form-group
                    :label="
                      isTextCopied
                        ? 'Colonne :   Text copier ...'
                        : 'Colonne : '
                    "
                    label-for="colonne"
                    class="input-modal-champ p-1 col-3"
                  >
                    <b-form-select
                      autocomplete="off"
                      :id="'condition_type'"
                      @input="CopyFields($event)"
                      :options="getNumeriqCulomn"
                      class="b-form-select-raduis"
                    ></b-form-select>
                    <!-- <div>
                          <div class="CopieMsg" v-if="true">
                            Text copier
                          </div>
                        </div> -->
                  </b-form-group>

                  <b-form-group
                    label="Formule finale"
                    label-for="formule"
                    class="input-modal-champ p-1 col-3"
                  >
                    <em class="d-flex justify-content-start text-dark">
                      {{
                        computedFormule(
                          organismeNewToUpdate.coef_commercial_prime
                        )
                      }}
                    </em>
                  </b-form-group>
                </div>
                <div class="row ml-1">
                  <b-form-group
                    label="Tva Coef prime"
                    label-for="tva_coef_commercial_prime"
                    class="input-modal-champ p-1 col-6"
                  >
                    <b-form-input
                      id="tva_coef_commercial_prime"
                      v-model="organismeNewToUpdate.tva_coef_commercial_prime"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Description Coef prime"
                    label-for="description__commercial_prime"
                    class="input-modal-champ p-1 col-6"
                  >
                    <b-form-textarea
                      :class="{
                        'textarea-societe':
                          organismeNewToUpdate.description__commercial_prime ==
                          null
                      }"
                      id="description__commercial_prime"
                      rows="3"
                      v-model="
                        organismeNewToUpdate.description__commercial_prime
                      "
                    ></b-form-textarea>
                  </b-form-group>
                </div>
                <div class="row ml-1">
                  <b-form-group
                    label="Label complémentaire"
                    label-for="label_missing_prime"
                    class="input-modal-champ p-1 col-6"
                  >
                    <b-form-input
                      id="label_missing_prime"
                      v-model="organismeNewToUpdate.label_missing_prime"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Description complémentaire"
                    label-for="description_missing_prime"
                    class="input-modal-champ p-1 col-6"
                  >
                    <b-form-textarea
                      :class="{
                        'textarea-societe':
                          organismeNewToUpdate.description_missing_prime == null
                      }"
                      id="description_missing_prime"
                      rows="2"
                      v-model="organismeNewToUpdate.description_missing_prime"
                    ></b-form-textarea>
                  </b-form-group>
                </div>

                <div class="row ml-1">
                  <b-form-group
                    label="Label complémentaire avoir"
                    label-for="label_missing_prime_av"
                    class="input-modal-champ p-1 col-6"
                  >
                    <b-form-input
                      id="label_missing_prime_av"
                      v-model="organismeNewToUpdate.label_missing_prime_av"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Description complémentaire avoir"
                    label-for="description_missing_prime_av"
                    class="input-modal-champ p-1 col-6"
                  >
                    <b-form-textarea
                      :class="{
                        'textarea-societe':
                          organismeNewToUpdate.description_missing_prime_av ==
                          null
                      }"
                      id="description_missing_prime_av"
                      rows="2"
                      v-model="
                        organismeNewToUpdate.description_missing_prime_av
                      "
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>

              <div class="col-12">
                <div class="row ml-1">
                  <b-form-group
                    label="Coef Prime Installateur"
                    label-for="coef_prime_installateur"
                    class="input-modal-champ p-1 col-6"
                  >
                    <b-form-input
                      id="coef_prime_installateur"
                      type="number"
                      step="any"
                      v-model="organismeNewToUpdate.coef_prime_installateur"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Tva Coef Prime Installateur"
                    label-for="tva_coef_prime_installateur"
                    class="input-modal-champ p-1 col-6"
                  >
                    <b-form-input
                      id="tva_coef_prime_installateur"
                      v-model="organismeNewToUpdate.tva_coef_prime_installateur"
                      autocomplete="off"
                      disabled
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="row ml-1">
                  <b-form-group
                    label="Label Coef commercial group"
                    label-for="label_commercial_group"
                    class="input-modal-champ p-1 col-3"
                  >
                    <b-form-input
                      id="label_commercial_group"
                      v-model="organismeNewToUpdate.label_commercial_group"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Coef commercial group"
                    label-for="coef_commercial_group"
                    class="input-modal-champ p-1 col-3"
                  >
                    <b-form-input
                      id="coef_commercial_group"
                      v-model="organismeNewToUpdate.coef_commercial_group"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Tva coef commercial groupe"
                    label-for="tva_coef_commercial_group"
                    class="input-modal-champ p-1 col-3"
                  >
                    <b-form-input
                      id="tva_coef_commercial_group"
                      v-model="organismeNewToUpdate.tva_coef_commercial_group"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Description coef commercial groupe"
                    label-for="description_commercial_group"
                    class="input-modal-champ p-1 col-3"
                  >
                    <b-form-textarea
                      :class="{
                        'textarea-societe':
                          organismeNewToUpdate.description_commercial_group ==
                          null
                      }"
                      id="description_commercial_group"
                      rows="3"
                      v-model="
                        organismeNewToUpdate.description_commercial_group
                      "
                    ></b-form-textarea>
                  </b-form-group>
                </div>
                <div class="row ml-1">
                  <b-form-group
                    label="Label Coef surprime group"
                    label-for="label_surprime_group"
                    class="input-modal-champ p-1 col-3"
                  >
                    <b-form-input
                      id="label_surprime_group"
                      v-model="organismeNewToUpdate.label_surprime_group"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Coef surprime group"
                    label-for="coef_surprime_group"
                    class="input-modal-champ p-1 col-3"
                  >
                    <b-form-input
                      id="coef_surprime_group"
                      v-model="organismeNewToUpdate.coef_surprime_group"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Tva coef surprime groupe"
                    label-for="tva_coef_surprime_group"
                    class="input-modal-champ p-1 col-3"
                  >
                    <b-form-input
                      id="tva_coef_surprime_group"
                      v-model="organismeNewToUpdate.tva_coef_surprime_group"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Description coef surprime groupe"
                    label-for="description_surprime_group"
                    class="input-modal-champ p-1 col-3"
                  >
                    <b-form-textarea
                      :class="{
                        'textarea-societe':
                          organismeNewToUpdate.description_surprime_group ==
                          null
                      }"
                      id="description_surprime_group"
                      rows="3"
                      v-model="organismeNewToUpdate.description_surprime_group"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
                <div class="row ml-1">
                  <b-form-group
                    label="Label Commissions Gestion dossier et administrative"
                    label-for="label_commission_gestion_dossier_administrative"
                    class="input-modal-champ p-1 col-3"
                  >
                    <b-form-input
                      id="label_commission_gestion_dossier_administrative"
                      v-model="
                        organismeNewToUpdate.label_commission_gestion_dossier_administrative
                      "
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Coef Commissions Gestion dossier et administrative"
                    label-for="coef_commission_gestion_dossier_administrative"
                    class="input-modal-champ p-1 col-3"
                  >
                    <b-form-input
                      id="coef_commission_gestion_dossier_administrative"
                      v-model="
                        organismeNewToUpdate.coef_commission_gestion_dossier_administrative
                      "
                      autocomplete="off"
                      required
                      type="number"
                      step="any"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Tva Commissions Gestion dossier et administrative"
                    label-for="tva_coef_commission_gestion_dossier_administrative"
                    class="input-modal-champ p-1 col-3"
                  >
                    <b-form-input
                      id="tva_coef_commission_gestion_dossier_administrative"
                      v-model="
                        organismeNewToUpdate.tva_coef_commission_gestion_dossier_administrative
                      "
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Description Commissions Gestion dossier et administrative"
                    label-for="description_commission_gestion_dossier_administrative"
                    class="input-modal-champ p-1 col-3"
                  >
                    <b-form-textarea
                      class="textarea-societe"
                      id="description_commission_gestion_dossier_administrative"
                      rows="3"
                      v-model="
                        organismeNewToUpdate.description_commission_gestion_dossier_administrative
                      "
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="actionModel mt-3">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)">
                    <li
                      v-for="(e, index) in error"
                      :key="index"
                      style="list-style-type: none"
                    >
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button
                :disabled="
                  organismeNewToUpdate.locked === 1 ||
                    loading ||
                    !validateToAddconf
                "
                type="submit"
                class="button-valide-style"
              >
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <b-modal
      ref="parametreSpec"
      id="parametreSpec"
      :hide-footer="true"
      :hide-header="true"
      no-close-on-backdrop
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-filiale-th"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Paramètres Spécifiques</div>
        <div class="iconClose" @click.prevent.stop="hideModal('parametreSpec')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <div
            v-if="parametreFixeOrganismeLoading"
            class="message text-aligne-center"
          >
            <div class="loading-custom-template-block style-half-circle-loder">
              <Half-circle-spinner
                :animation-duration="1000"
                :size="50"
                :color="'#4d4bac'"
                class="loeder"
              />
            </div>
          </div>
          <template v-else>
            <div class="button-add-prop-filiale-th">
              <b-button
                variant="success"
                class="bien-add-button ml-2"
                @click="addLineParametreSpec()"
                v-if="checkPermission('GOTHAPSO')"
              >
                <font-awesome-icon icon="plus" /> Ajouter un paramètre
                spécifique
              </b-button>
            </div>
            <b-form-group
              label=""
              label-for="filiale-swift"
              class="input-modal-champ mb-0 mt-2"
            >
              <b-row
                class="mb-1 parametre_block"
                v-for="(parametre, index) in parametreSpec"
                :key="'index' + index"
              >
                <b-row>
                  <b-col col xs="10" sm="10" md="10">
                    <b-form-group
                      label="Société"
                      label-for="Société : "
                      class="vue-select-modal-champ"
                      label-cols-sm="4"
                      label-cols-lg="2"
                      content-cols-sm
                      content-cols-lg="10"
                    >
                      <select-simple-with-search
                        :value="parametre.filiale_id.id"
                        :options="getFiliaeOfResponsable"
                        placeholder="sélectionner une Sociéte"
                        @changeValue="parametre.filiale_id.id = $event"
                        :closeOnSelect="false"
                        :clearOnSelect="false"
                        :preserveSearch="true"
                        :preselectFirst="false"
                        :refreshValue="true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col col xs="2" sm="2" md="2">
                    <div class="edit-action">
                      <div class="action-icon validate ml-3 mr-2">
                        <font-awesome-icon
                          @click.prevent.stop="
                            handleSubmitParametre(parametre, index)
                          "
                          v-if="checkPermission('GOTHAPSO')"
                          title="Ajouter"
                          icon="check"
                          class="fa-2x bell1 m-1"
                        />
                      </div>
                      <div class="action-icon cancel ml-3">
                        <font-awesome-icon
                          icon="trash"
                          class="fa-2x bell1 m-2"
                          title="supprimer"
                          @click.prevent.stop="deleteBlockParametre(index)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-col col xs="3" sm="3" md="3">
                  <b-form-group
                    label="Coefficient prime Installateur"
                    label-for="iban-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="iban-template-input"
                      v-model="parametre.coef_prime_installateur"
                      type="number"
                      min="0"
                      step="0.01"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col col xs="3" sm="3" md="3">
                  <b-form-group
                    label="TVA Coefficient  prime Installateur"
                    label-for="swift-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      id="swift-template-input"
                      v-model="parametre.tva_coef_prime_installateur"
                      type="number"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col col xs="3" sm="3" md="3">
                  <b-form-group
                    label="Coefficient Commercial Installateur"
                    label-for="swift-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="swift-template-input"
                      v-model="parametre.coef_commercial_prime"
                      type="text"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col col xs="3" sm="3" md="3">
                  <b-form-group
                    label="TVA Coefficient  Commercial Installateur"
                    label-for="swift-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="swift-template-input"
                      v-model="parametre.tva_coef_commercial_prime"
                      type="number"
                      min="0"
                      step="0.01"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col col xs="3" sm="3" md="3">
                  <b-form-group
                    label="Coefficient Commercial groupe"
                    label-for="swift-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="swift-template-input"
                      v-model="parametre.coef_commercial_group"
                      type="number"
                      min="0"
                      step="0.01"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col col xs="3" sm="3" md="3">
                  <b-form-group
                    label="TVA Coefficient  Commercial groupe"
                    label-for="swift-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="swift-template-input"
                      v-model="parametre.tva_coef_commercial_group"
                      type="number"
                      min="0"
                      step="0.01"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col col xs="3" sm="3" md="3">
                  <b-form-group
                    label="Coefficient surprime groupe"
                    label-for="swift-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="swift-template-input"
                      v-model="parametre.coef_surprime_group"
                      type="number"
                      min="0"
                      step="0.01"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col col xs="3" sm="3" md="3">
                  <b-form-group
                    label="TVA Coefficient surprime groupe"
                    label-for="swift-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="swift-template-input"
                      v-model="parametre.tva_coef_surprime_group"
                      type="number"
                      min="0"
                      step="0.01"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col col xs="3" sm="3" md="3">
                  <b-form-group
                    label="Coefficient Commissions Gestion dossier et administrative "
                    label-for="swift-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="swift-template-input"
                      v-model="
                        parametre.coef_commission_gestion_dossier_administrative
                      "
                      type="number"
                      min="0"
                      step="0.01"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col col xs="3" sm="3" md="3">
                  <b-form-group
                    label="TVA Coefficient Commissions Gestion dossier et administrative"
                    label-for="swift-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="swift-template-input"
                      v-model="
                        parametre.tva_coef_commission_gestion_dossier_administrative
                      "
                      required
                      type="number"
                      min="0"
                      step="0.01"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <div
                  class="actionModel"
                  v-if="parametreFixeOrganismeError && indexParametre == index"
                >
                  <div class="messageError">
                    <div v-if="parametreFixeOrganismeError" class="error">
                      <ul v-if="Array.isArray(parametreFixeOrganismeError)">
                        <li
                          class="color-message"
                          style="list-style: none; color: red"
                          v-for="(e, index) in parametreFixeOrganismeError"
                          :key="index"
                        >
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else class="color-message">
                        {{ parametreFixeOrganismeError }}
                      </div>
                    </div>
                  </div>
                </div>
              </b-row>
              <b-row
                class="mb-1 parametre_block"
                v-for="(parametre, index) in parametreFixeOrganisme"
                :key="index"
              >
                <b-row>
                  <b-col col xs="10" sm="10" md="10">
                    <b-form-group
                      label="Société"
                      label-for="Société : "
                      class="vue-select-modal-champ"
                      label-cols-sm="4"
                      label-cols-lg="2"
                      content-cols-sm
                      content-cols-lg="10"
                    >
                      <select-simple-with-search
                        :value="parametre.filiale_id.id"
                        :options="getFiliaeOfResponsable"
                        placeholder="sélectionner une Sociéte"
                        @changeValue="parametre.filiale_id.id = $event"
                        :disabled="indexUpdate != 'parametre' + index"
                        :closeOnSelect="false"
                        :clearOnSelect="false"
                        :preserveSearch="true"
                        :preselectFirst="false"
                        :refreshValue="true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col col xs="2" sm="2" md="2">
                    <div class="edit-action">
                      <div
                        class="action-icon validate ml-3 mr-2"
                        @click.prevent.stop="
                          updateParametreSpec(parametre, 'parametre' + index)
                        "
                        v-if="checkPermission('GOTHMPSO')"
                      >
                        <font-awesome-icon
                          v-if="indexUpdate == 'parametre' + index"
                          title="Valider la modification"
                          icon="check"
                          class="fa-2x bell1 m-1"
                        />
                      </div>
                      <div
                        class="action-icon validate"
                        @click="changeDisabled('parametre' + index)"
                      >
                        <font-awesome-icon
                          v-if="
                            showModify == false && checkPermission('GOTHMPSO')
                          "
                          title="Modifier"
                          icon="pen"
                          class="fa-2x bell1 m-1"
                        />
                      </div>
                      <div class="action-icon cancel ml-3">
                        <font-awesome-icon
                          icon="trash"
                          class="fa-2x bell1 m-2"
                          v-if="checkPermission('GOTHMSPSO')"
                          title="supprimer"
                          @click.prevent.stop="handleModalDelete(parametre)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-col col xs="3" sm="3" md="3">
                  <b-form-group
                    label="Coefficient prime Installateur"
                    label-for="iban-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      :class="'parametre' + index"
                      v-model="parametre.coef_prime_installateur"
                      type="number"
                      min="0"
                      step="0.01"
                      required
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col col xs="3" sm="3" md="3">
                  <b-form-group
                    label="TVA Coefficient  prime Installateur"
                    label-for="swift-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      :class="'parametre' + index"
                      v-model="parametre.tva_coef_prime_installateur"
                      type="number"
                      required
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col col xs="3" sm="3" md="3">
                  <b-form-group
                    label="Coefficient Commercial Installateur"
                    label-for="swift-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      :class="'parametre' + index"
                      v-model="parametre.coef_commercial_prime"
                      type="text"
                      required
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col col xs="3" sm="3" md="3">
                  <b-form-group
                    label="TVA Coefficient  Commercial Installateur"
                    label-for="swift-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      :class="'parametre' + index"
                      v-model="parametre.tva_coef_commercial_prime"
                      type="number"
                      min="0"
                      step="0.01"
                      required
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col col xs="3" sm="3" md="3">
                  <b-form-group
                    label="Coefficient Commercial groupe"
                    label-for="swift-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      :class="'parametre' + index"
                      v-model="parametre.coef_commercial_group"
                      type="number"
                      min="0"
                      step="0.01"
                      required
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col col xs="3" sm="3" md="3">
                  <b-form-group
                    label="TVA Coefficient  Commercial groupe"
                    label-for="swift-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      :class="'parametre' + index"
                      v-model="parametre.tva_coef_commercial_group"
                      type="number"
                      min="0"
                      step="0.01"
                      required
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col col xs="3" sm="3" md="3">
                  <b-form-group
                    label="Coefficient surprime groupe"
                    label-for="swift-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      :class="'parametre' + index"
                      v-model="parametre.coef_surprime_group"
                      type="number"
                      min="0"
                      step="0.01"
                      required
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col col xs="3" sm="3" md="3">
                  <b-form-group
                    label="TVA Coefficient surprime groupe"
                    label-for="swift-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      :class="'parametre' + index"
                      v-model="parametre.tva_coef_surprime_group"
                      type="number"
                      min="0"
                      step="0.01"
                      required
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col col xs="3" sm="3" md="3">
                  <b-form-group
                    label="Coefficient Commissions Gestion dossier et administrative "
                    label-for="swift-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      :class="'parametre' + index"
                      v-model="
                        parametre.coef_commission_gestion_dossier_administrative
                      "
                      type="number"
                      min="0"
                      step="0.01"
                      :disabled="true"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col col xs="3" sm="3" md="3">
                  <b-form-group
                    label="TVA Coefficient Commissions Gestion dossier et administrative"
                    label-for="swift-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      :class="'parametre' + index"
                      v-model="
                        parametre.tva_coef_commission_gestion_dossier_administrative
                      "
                      required
                      :disabled="true"
                      type="number"
                      min="0"
                      step="0.01"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <div
                  class="actionModel"
                  v-if="parametreFixeOrganismeError && indexParametre == index"
                >
                  <div class="messageError">
                    <div v-if="parametreFixeOrganismeError" class="error">
                      <ul v-if="Array.isArray(parametreFixeOrganismeError)">
                        <li
                          class="color-message"
                          style="list-style: none; color: red"
                          v-for="(e, index) in parametreFixeOrganismeError"
                          :key="index"
                        >
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else class="color-message">
                        {{ parametreFixeOrganismeError }}
                      </div>
                    </div>
                  </div>
                </div>
              </b-row>
            </b-form-group>
            <div class="actionModel">
              <b-button
                type="submit"
                class="button-valide-style mt-1"
                @click.prevent.stop="hideModal('parametreSpec')"
              >
                <span> Fermer </span>
              </b-button>
            </div>
          </template>
        </template>
      </Card>
    </b-modal>
    <b-modal
      ref="deleteParametreSpec"
      id="deleteParametreSpec"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Supprimer un paramètre spécifique</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('deleteParametreSpec')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleDeleteParametreSpec"
            class="form-modal-custom-style"
          >
            <div class="mt-4 ml-3">
              <p class="text-dark">
                Êtes-vous sur de vouloir supprimer ce paramètre spécifique ?
              </p>
            </div>
            <div class="actionModel">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)">
                    <li v-for="(e, index) in error" :key="'error' + index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style mt-1">
                <span>
                  Valider
                  <div v-if="loadingDeleteParametre" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import SelectSimpleWithSearch from '../component/selectSimpleWithSearch.vue';
export default {
  name: 'Gestion-des-organismes',
  data() {
    return {
      loadingDeleteParametre: false,
      parametreSpecToDelete: null,
      parametreToUpdate: null,
      showModify: false,
      indexUpdate: null,
      showUpdate: true,
      parametreSpec: [],
      errorFormule: null,
      isTextCopied: false,
      indexParametre: null,
      organismeNewToAdd: {
        organisme: null,
        group: null,
        coef_prime_installateur: null,
        tva_coef_prime_installateur: 0,
        coef_commercial_prime: null,
        tva_coef_commercial_prime: 20,
        coef_commercial_group: null,
        tva_coef_commercial_group: 20,
        coef_surprime_group: null,
        tva_coef_surprime_group: 20,
        date_debut: null,
        date_fin: null,
        label_commercial_prime: null,
        description__commercial_prime: null,
        label_commercial_group: null,
        description_commercial_group: null,
        label_surprime_group: null,
        description_surprime_group: null,
        label_missing_prime: null,
        description_missing_prime: null,
        label_missing_prime_av: null,
        description_missing_prime_av: null,
        coef_commission_gestion_dossier_administrative: null,
        tva_coef_commission_gestion_dossier_administrative: 20,
        label_commission_gestion_dossier_administrative: null,
        description_commission_gestion_dossier_administrative: null,
        locked: 0
      },
      checkAll: false,
      dateStartForSearch: null,
      dateEndForSearch: null,
      error: null,
      loading: false,
      selectedOrganisme: null,
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 30, text: 30 }
      ],
      fields: [
        {
          key: 'check_all',
          label: '',
          thClass: 'th-color',
          tdClass: 'color'
        },
        {
          key: 'lock_icon',
          label: '',
          tdClass: 'details-column-frais',
          thClass: 'th-details-column-frais'
        },
        { key: 'organisme', label: 'Organisme' },
        { key: 'group', label: 'Group' },
        { key: 'date_debut', label: 'Date début' },
        { key: 'date_fin', label: 'Date fin' },
        { key: 'coef_prime_installateur', label: 'Coef Prime Installateur' },
        { key: 'coef_commercial_prime', label: 'Coef commercial prime' },
        { key: 'coef_commercial_group', label: 'Coef commercial group' },
        { key: 'coef_surprime_group', label: 'Coef surprime group' },
        {
          key: 'coef_commission_gestion_dossier_administrative',
          label: 'Coef Commissions Gestion dossier et administrative'
        },
        { key: 'Actions', label: 'Actions' }
      ],
      objToSend: {
        organisme_id: null,
        group_id: null,
        date_debut: null,
        date_fin: null,
        id: null
      },
      // organismeNewToUpdate: {
      //   organisme: null,
      //   group: null,
      //   coef_prime_installateur: null,
      //   tva_coef_prime_installateur: 0,
      //   coef_commercial_prime: 0,
      //   tva_coef_commercial_prime: 20,
      //   coef_commercial_group: null,
      //   tva_coef_commercial_group: 20,
      //   coef_surprime_group: null,
      //   tva_coef_surprime_group: 20,
      //   date_debut: null,
      //   date_fin: null,
      //   label_commercial_prime: null,
      //   description__commercial_prime: null,
      //   label_commercial_group: null,
      //   description_commercial_group: null,
      //   label_surprime_group: null,
      //   description_surprime_group: null,
      //   label_missing_prime: null,
      //   description_missing_prime: null,
      //   label_missing_prime_av: null,
      //   description_missing_prime_av: null
      // },
      organismeNewToUpdate: null,
      filterOrganisme: null,
      filterGroup: null,
      filterDate_debut: null,
      filterDate_fin: null,
      ListOrganisme: [],
      ListGroup: [],
      validateToAddconf: false,
      errorDupplicate: null
    };
  },
  computed: {
    ...mapGetters([
      'getFiliaeOfResponsable',
      'getOrganismeThNew',
      'getOrganismeThNewLoading',
      'getOrganismeThNewError',
      'getSettingFilialesTh',
      'getTotalRowOrganismeThNew',
      'getOrganismeThNewErrorDupplication',
      'checkPermission',
      'getNumeriqCulomn',
      'parametreFixeOrganismeError',
      'parametreFixeOrganisme',
      'parametreFixeOrganismeLoading',
      'formateDateGetters'
    ]),
    sizeBlockTable() {
      let heigthBlock = 25;
      let hei = 239 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    sizeTable() {
      let heigthBlock = 25;
      let hei = 270 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },

    computedFilterGroup() {
      let name = '';
      this.computedListGroup.map(item => {
        if (item.id == this.filterGroup) {
          name = item.name;
        }
      });
      return name;
    },
    computedFilterOrganisme() {
      let name = '';
      this.computedListOrganisme.map(item => {
        if (item.id == this.filterOrganisme) {
          name = item.name;
        }
      });
      return name;
    },
    computedFormule() {
      return function(data) {
        if (data) {
          let formule = data;
          Object.keys(this.getNumeriqCulomn).forEach(key => {
            formule = formule.replaceAll('$' + key, this.getNumeriqCulomn[key]);
          });

          return formule;
        }
        return '';
      };
    },
    computedCheckedRows() {
      let checkedRows = this.getOrganismeThNew.filter(
        item => item.check === true
      );
      return checkedRows;
    },
    computedListOrganisme() {
      let tab = this.getSettingFilialesTh.filter(
        element => element.type === 'organisme'
      );

      tab = tab.map(item => ({
        id: item.id,
        name: item.name
      }));
      return tab;
    },
    computedListGroup() {
      const tab = this.getSettingFilialesTh
        .filter(i => i.type != 'particulier')
        .map(item => ({
          id: item.id,
          name: item.name
        }));
      return tab;
    }
  },
  methods: {
    ...mapActions([
      'fetchAllParametreSpecifique',
      'fetchAllOrganismeThNew',
      'addNewOrganismeThNew',
      'EditOrganismeTh',
      'deletOrganismeThNew',
      'getSettingFilialeTh',
      'checkPeriodOrganism',
      'lock_unlock',
      'checkFormuleConfigFraisDynamicMethod',
      'getAllNumColums',
      'getFilialsOfConnectedResponsable',
      'addNewParametreSpecfique',
      'deleteParametreSpecifique',
      'updateParametreSpecifique'
    ]),

    handleModalDelete(parametre) {
      this.parametreSpecToDelete = parametre;
      this.$refs['deleteParametreSpec'].show();
    },
    changeDisabled(index) {
      this.indexUpdate = index;
      this.showModify = true;
      const elem = document.getElementsByClassName(index);
      elem.forEach(element => {
        element.removeAttribute('disabled');
      });
    },
    async updateParametreSpec(parametre, index) {
      this.parametreToUpdate = parametre;
      this.indexUpdate = index;
      this.showUpdate = false;
      let parametreSpec = {
        id: parametre.id,
        coef_prime_installateur: parseFloat(parametre.coef_prime_installateur),
        tva_coef_prime_installateur: parseFloat(
          parametre.tva_coef_prime_installateur
        ),
        coef_commercial_prime: parametre.coef_commercial_prime,
        tva_coef_commercial_prime: parseFloat(
          parametre.tva_coef_commercial_prime
        ),
        coef_commercial_group: parseFloat(parametre.coef_commercial_group),
        tva_coef_commercial_group: parseFloat(
          parametre.tva_coef_commercial_group
        ),
        coef_surprime_group: parseFloat(parametre.coef_surprime_group),
        tva_coef_surprime_group: parseFloat(parametre.tva_coef_surprime_group),
        coef_commission_gestion_dossier_administrative: parseFloat(
          parametre.coef_commission_gestion_dossier_administrative
        ),
        tva_coef_commission_gestion_dossier_administrative: parseFloat(
          parametre.tva_coef_commission_gestion_dossier_administrative
        )
      };
      const response = await this.updateParametreSpecifique({
        parametre: parametreSpec,
        oldParametre: this.parametreToUpdate
      });
      if (response.success) {
        const elem = document.getElementsByClassName(index);
        elem.forEach(element => {
          element.setAttribute('disabled', '');
        });
        this.showModify = false;
        this.indexUpdate = null;
      }
    },
    async handleDeleteParametreSpec() {
      this.loadingDeleteParametre = true;
      const response = this.deleteParametreSpecifique(
        this.parametreSpecToDelete.id
      );
      if (response) {
        this.loadingDeleteParametre = false;
        this.hideModal('deleteParametreSpec');
      }
    },
    async handleModalParametreSpec() {
      this.$refs['parametreSpec'].show();
      await this.fetchAllParametreSpecifique({
        config_id: this.organismeNewToUpdate.id
      });
    },
    async handleSubmitParametre(parametre, index) {
      this.indexParametre = index;
      let parametreSpec = {
        config_id: this.organismeNewToUpdate.id,
        filiale_id: parametre.filiale_id.id,
        coef_prime_installateur: parametre.coef_prime_installateur,
        tva_coef_prime_installateur: parseFloat(
          parametre.tva_coef_prime_installateur
        ),
        coef_commercial_prime: parametre.coef_commercial_prime,
        tva_coef_commercial_prime: parseFloat(
          parametre.tva_coef_commercial_prime
        ),
        coef_commercial_group: parseFloat(parametre.coef_commercial_group),
        tva_coef_commercial_group: parseFloat(
          parametre.tva_coef_commercial_group
        ),
        coef_surprime_group: parseFloat(parametre.coef_surprime_group),
        tva_coef_surprime_group: parseFloat(parametre.tva_coef_surprime_group),
        coef_commission_gestion_dossier_administrative: parseFloat(
          parametre.coef_commission_gestion_dossier_administrative
        ),
        tva_coef_commission_gestion_dossier_administrative: parseFloat(
          parametre.tva_coef_commission_gestion_dossier_administrative
        )
      };
      const response = await this.addNewParametreSpecfique(parametreSpec);
      if (response.success) {
        this.showUpdate = true;
        this.deleteBlockParametre(index);
        const elem = document.getElementsByClassName('parametre' + index);
        elem.forEach(element => {
          element.setAttribute('disabled', '');
        });
        this.showModify = false;
      }
    },
    deleteBlockParametre(index) {
      this.parametreSpec.splice(index, 1);
    },
    addLineParametreSpec() {
      this.parametreSpec.unshift({
        filiale_id: { id: null },
        coef_prime_installateur: this.organismeNewToUpdate
          .coef_prime_installateur,
        tva_coef_prime_installateur: this.organismeNewToUpdate
          .tva_coef_prime_installateur,
        coef_commercial_prime: this.organismeNewToUpdate.coef_commercial_prime,
        tva_coef_commercial_prime: this.organismeNewToUpdate
          .coef_prime_installateur,
        coef_commercial_group: this.organismeNewToUpdate.coef_commercial_group,
        tva_coef_commercial_group: this.organismeNewToUpdate
          .tva_coef_commercial_group,
        coef_surprime_group: this.organismeNewToUpdate.coef_surprime_group,
        tva_coef_surprime_group: this.organismeNewToUpdate
          .tva_coef_surprime_group,
        coef_commission_gestion_dossier_administrative: this
          .organismeNewToUpdate.coef_commission_gestion_dossier_administrative,
        tva_coef_commission_gestion_dossier_administrative: this
          .organismeNewToUpdate
          .tva_coef_commission_gestion_dossier_administrative
      });
    },
    async checkFormuleConfigFraisDynamic(type, index) {
      let value =
        type === 'add'
          ? this.organismeNewToAdd.coef_commercial_prime
          : this.organismeNewToUpdate.coef_commercial_prime;
      // type === 'add'
      //   ? (this.configurationToAdd.formules[index].error = null)
      //   : (this.configurationToUpdate.formules[index].error = null);
      const response = await this.checkFormuleConfigFraisDynamicMethod({
        formule: value
      });
      if (response && response.success == false) {
        this.errorFormule = response.error;
      } else {
        this.errorFormule = null;
      }
    },
    CopyFields(payload) {
      var textarea = document.createElement('textarea');
      textarea.textContent = '$' + payload;
      document.body.appendChild(textarea);
      var selection = document.getSelection();
      var range = document.createRange();
      range.selectNode(textarea);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
      selection.removeAllRanges();

      document.body.removeChild(textarea);
      this.isTextCopied = true;
      setTimeout(() => {
        this.isTextCopied = false;
      }, 800);
    },
    checkAllFunction() {
      this?.getOrganismeThNew?.map(item => (item.check = this.checkAll));
    },
    checkOneFunction(data) {
      data.check == !data.check;
    },
    async handelBlokedRows() {
      let checkesRows = this?.getOrganismeThNew?.filter(
        row => row.check == true
      );
      let ids = [];
      checkesRows.map(item => ids.push(item.id));
      const response = await this.lock_unlock(ids);
      if (response) {
        this.handleFilter();
      }
    },
    disabledStartDate(date) {
      return (
        this.organismeNewToAdd.date_fin &&
        moment(date, 'YYYY-MM-DD') >= new Date(this.organismeNewToAdd.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.organismeNewToAdd.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') <
          new Date(this.organismeNewToAdd.date_debut)
      );
    },

    disabledStartDateFilter(date) {
      return (
        this.dateEndForSearch &&
        moment(date, 'YYYY-MM-DD') >= new Date(this.dateEndForSearch)
      );
    },
    disabledEndDateFiltre(date) {
      return (
        this.dateStartForSearch &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') <
          new Date(this.dateStartForSearch)
      );
    },
    disabledStartDateUpdate(date) {
      return (
        this.organismeNewToUpdate.date_fin &&
        moment(date, 'YYYY-MM-DD') >=
          new Date(this.organismeNewToUpdate.date_fin)
      );
    },
    disabledEndDateUpdate(date) {
      return (
        this.organismeNewToUpdate.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') <
          new Date(this.organismeNewToUpdate.date_debut)
      );
    },
    resetModal() {
      this.validateToAddconf = true;
      this.loadingDeleteParametre = false;
      this.errorFormule = null;
      this.parametreSpec = [];
      this.organismeNewToAdd = {
        organisme: null,
        group: null,
        coef_prime_installateur: null,
        tva_coef_prime_installateur: 0,
        coef_commercial_prime: 0,
        tva_coef_commercial_prime: 20,
        coef_commercial_group: null,
        tva_coef_commercial_group: 20,
        coef_surprime_group: null,
        tva_coef_surprime_group: 20,
        date_debut: null,
        date_fin: null,
        label_commercial_prime: null,
        description__commercial_prime: null,
        label_commercial_group: null,
        description_commercial_group: null,
        label_surprime_group: null,
        description_surprime_group: null,
        label_missing_prime: null,
        description_missing_prime: null,
        label_missing_prime_av: null,
        description_missing_prime_av: null,
        coef_commission_gestion_dossier_administrative: null,
        tva_coef_commission_gestion_dossier_administrative: 20,
        label_commission_gestion_dossier_administrative: null,
        description_commission_gestion_dossier_administrative: null,
        locked: 0
      };
      this.error = null;
      this.errorDupplicate = null;
      this.loading = false;
      this.validateToAddconf = false;
      this.objToSend = {
        organisme_id: null,
        group_id: null,
        date_debut: null,
        date_fin: null,
        id: null
      };
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async hendleSubmitOrganismeNew() {
      this.loading = true;
      if (
        this.organismeNewToAdd.date_debut === null ||
        this.organismeNewToAdd.date_debut == ''
      ) {
        this.error = 'date debut is required';
        this.loading = false;
      } else {
        const response = await this.addNewOrganismeThNew(
          this.organismeNewToAdd
        );
        if (response.succes) {
          this.loading = false;
          this.error = null;
          this.errorDupplicate = null;
          this.validateToAddconf = false;
          this.hideModal('addOrganismeNew');
        } else {
          this.error = response.error;
          this.loading = false;
        }
      }
    },
    handleChangeOrganisme() {
      this.handleFilter();
    },
    handleChangeGroup() {
      this.handleFilter();
    },
    handleChangeDate_debut() {
      this.handleFilter();
    },
    handleChangeDate_fin() {
      this.handleFilter();
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    handleFilter() {
      this.fetchAllOrganismeThNew({
        per_page: this.perPage,
        page: this.page,
        organisme: this.filterOrganisme,
        group: this.filterGroup,
        date_debut: this.dateStartForSearch,
        date_fin: this.dateEndForSearch
      });
    },
    DupplicateOrganismeNew(item) {
      this.organismeNewToAdd = {
        organisme: item.organisme.id,
        group: item.group.id,
        coef_prime_installateur: item.coef_prime_installateur,
        tva_coef_prime_installateur: item.tva_coef_prime_installateur,
        coef_commercial_prime: item.coef_commercial_prime,
        tva_coef_commercial_prime: item.tva_coef_commercial_prime,
        coef_commercial_group: item.coef_commercial_group,
        tva_coef_commercial_group: item.tva_coef_commercial_group,
        coef_surprime_group: item.coef_surprime_group,
        tva_coef_surprime_group: item.tva_coef_surprime_group,
        date_debut: item.date_debut,
        date_fin: item.date_fin,
        label_commercial_prime: item.label_commercial_prime,
        description__commercial_prime: item.description__commercial_prime,
        label_commercial_group: item.label_commercial_group,
        description_commercial_group: item.description_commercial_group,
        label_surprime_group: item.label_surprime_group,
        description_surprime_group: item.description_surprime_group,
        label_missing_prime: item.label_missing_prime,
        description_missing_prime: item.description_missing_prime,
        label_missing_prime_av: item.label_missing_prime_av,
        description_missing_prime_av: item.description_missing_prime_av,
        coef_commission_gestion_dossier_administrative:
          item.coef_commission_gestion_dossier_administrative,
        tva_coef_commission_gestion_dossier_administrative:
          item.tva_coef_commission_gestion_dossier_administrative,
        label_commission_gestion_dossier_administrative:
          item.label_commission_gestion_dossier_administrative,
        description_commission_gestion_dossier_administrative:
          item.description_commission_gestion_dossier_administrative,
        id: null
      };
      this.organismeNewToAdd.date_debut = moment(
        this.organismeNewToAdd.date_debut,
        'DD-MM-YYYY'
      ).format('YYYY-MM-DD');
      if (this.organismeNewToAdd.date_fin != null) {
        this.organismeNewToAdd.date_fin = moment(
          this.organismeNewToAdd.date_fin,
          'DD-MM-YYYY'
        ).format('YYYY-MM-DD');
      }
      this.checkErrorsAdd();
      // this.organismeNewToAdd = { ...item };
      this.$refs['addOrganismeNew'].show();
    },
    handleUpdate(item) {
      this.organismeNewToUpdate = { ...item };
      this.$refs['modal-update-organisme-params'].show();
      this.organismeNewToUpdate.date_debut = moment(
        this.organismeNewToUpdate.date_debut,
        'DD-MM-YYYY'
      ).format('YYYY-MM-DD');
      this.organismeNewToUpdate.date_fin =
        this.organismeNewToUpdate.date_fin != null &&
        this.organismeNewToUpdate.date_fin != ''
          ? moment(this.organismeNewToUpdate.date_fin, 'DD-MM-YYYY').format(
              'YYYY-MM-DD'
            )
          : this.organismeNewToUpdate.date_fin;
    },
    deleteOrganismeNew(item) {
      this.selectedOrganisme = item;
      this.$refs['deleteOrganismeThNewModal'].show();
    },
    async handleSuprimerOrganismeNew() {
      const response = this.deletOrganismeThNew(this.selectedOrganisme);
      if (response) {
        this.hideModal('deleteOrganismeThNewModal');
      }
    },
    async handleSubmitUpdateOrganismeNew() {
      this.loading = true;
      if (
        this.organismeNewToUpdate.date_debut === null ||
        this.organismeNewToUpdate.date_debut == ''
      ) {
        this.error = 'date debut is required';
        this.loading = false;
      } else {
        const response = await this.EditOrganismeTh(this.organismeNewToUpdate);

        if (response) {
          this.hideModal('modal-update-organisme-params');
        } else {
          this.loading = false;
        }
      }
    },
    async checkErrorsAdd() {
      var objToSend = {
        organisme_id: this.organismeNewToAdd.organisme
          ? this.organismeNewToAdd.organisme
          : null,
        group_id: this.organismeNewToAdd.group
          ? this.organismeNewToAdd.group
          : null,
        date_debut: this.organismeNewToAdd.date_debut
          ? this.organismeNewToAdd.date_debut
          : null,
        date_fin: this.organismeNewToAdd.date_fin
          ? this.organismeNewToAdd.date_fin
          : null,
        id: null
      };

      if (objToSend.organisme_id != null && objToSend.group_id != null) {
        const response = await this.checkPeriodOrganism(objToSend);
        if (response.success) {
          this.validateToAddconf = true;
          this.errorDupplicate = null;
        } else {
          this.errorDupplicate = response.errorDupplicate;
          this.validateToAddconf = false;
        }
      }
    },
    async checkErrorsUpdate() {
      var objToSend = {
        organisme_id: this.organismeNewToUpdate.organisme.id
          ? this.organismeNewToUpdate.organisme.id
          : null,
        group_id: this.organismeNewToUpdate.group.id
          ? this.organismeNewToUpdate.group.id
          : null,
        date_debut: this.organismeNewToUpdate.date_debut
          ? this.organismeNewToUpdate.date_debut
          : null,
        date_fin: this.organismeNewToUpdate.date_fin
          ? this.organismeNewToUpdate.date_fin
          : null,
        id: this.organismeNewToUpdate.id ? this.organismeNewToUpdate.id : null
      };

      if (objToSend.organisme_id != null && objToSend.group_id != null) {
        const response = await this.checkPeriodOrganism(objToSend);

        if (response.success) {
          this.validateToAddconf = true;
          this.errorDupplicate = null;
        } else {
          this.errorDupplicate = response.errorDupplicate;
          this.validateToAddconf = false;
        }
      }
    }
  },
  components: {
    Card: () => import('../component/card'),
    DatePicker,
    SelectSimpleWithSearch
  },
  async mounted() {
    this.getSettingFilialeTh();
    this.getAllNumColums();
    this.getFilialsOfConnectedResponsable({ minimum: 'yes' });
    // this.fetchAllOrganismeThNew();
    this.handleFilter();
  }
};
</script>

<style lang="scss" scoped>
.messageError {
  font-size: 13px;
  font-weight: 600;
}
.gestion-organisme-new-th
  .organisme-new-body
  .organisme-details
  .custom-table-style {
  padding: 5px;
  max-height: calc(100vh - 310px) !important;
  height: calc(100vh - 3100px) !important;
}
.parametre_block {
  color: #000;
  position: relative;
  overflow: visible;
  font-size: 12px;
  margin-top: 5px;
  border: 1px solid rgba(108, 117, 125, 0.3215686275);
  padding: 4px;
  background-color: #f5f4fa;
  width: 99%;
  border-radius: 4px;
  margin: 0px auto;

  .button-add-prop-filiale-th {
    display: flex;
  }

  .edit-action {
    width: 5%;
    display: flex;
    font-size: 7px;
    justify-content: space-between;
    // height: 80px;
    align-content: center;
    align-items: center;
    margin-right: 2%;

    .action-icon {
      cursor: pointer;
    }

    .validate {
      color: green;
    }

    .cancel {
      color: #ab2525;
    }
  }
}
.gestion-organisme-new-th {
  height: 100%;
  .tabs-organisme {
    width: 25%;
    margin: 5px 15px;
    .custom-list-group {
      .list-group-item {
        justify-content: space-between;
        color: #515356;
        display: block;
        font-weight: 600;
        .list-item {
          justify-content: space-between;
        }
      }
    }
    .title {
      color: #515356;
      display: block;
      font-weight: 600;
      margin: 0px 0px 10px 0px;
    }
    .organisme-labels {
      display: flex;
      justify-content: space-between;
    }
    .trash-style {
      margin-left: 5px;
      color: #dc3545;
    }
  }

  .organisme-new-body {
    display: flex;
    margin: 5px 15px;

    height: calc(100vh - 194px) !important;
    .organisme-details {
      width: 100%;
      .title {
        color: #515356;
        display: block;
        font-weight: 600;
        margin: 5px;
      }
      .input-modal-champ {
        input {
          border-radius: 5px;
        }
      }
      .custom-table-style {
        padding: 5px;
        max-height: calc(100vh - 280px) !important;
        height: calc(100vh - 280px) !important;
        .button-succes-style,
        .button-danger-style,
        .btn-secondary {
          border-radius: 6px;
          padding: 3px 5px;
          font-size: 12px;
          width: 25px;
          height: 25px;
          margin: 2px;
        }
      }
    }
  }
  .ligne-box-setting-header {
    margin-top: 1%;
  }
}
.label-box-style {
  width: 65px;
}
.radues-locked-icon {
  border-radius: 30px;
}
.box-all-filter .mx-datepicker {
  width: 110px;
}

.form-modal-custom-style .form-group .d-block {
  margin-left: 0px !important;
}
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
</style>
<style lang="scss">
.style-half-circle-loder {
  padding-top: 0px !important;
  background-color: #fff !important;
}
.cutsom-modal-bootstrap-filiale-th .modal-dialog {
  top: 0%;
  max-width: 95%;
  width: 95%;
}
.box-all-filter .mx-input {
  height: 31px !important;
  font-size: 11px !important;
  border: 0px solid #ccc;
  border-radius: 0px 18px 18px 0px;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 1100px;
  }
}
.table-organisme-new td,
.table-rapport-style th {
  padding: 7px !important;
}
.textarea-societe {
  line-height: 0.9;
}
</style>
